import { useEffect } from "react";
import { LogProvider, makeLogs } from "react-logger-js";
import LOGO from "./logo.png";

const makeLogsJSON = `makeLogs("json", {
  "name": "Varun Bardwaj",
  "city": "Bengaluru",
  "address": {
    "address_line_1": "random line 1",
    "address_line_2": "random line 2",
    "landmark": "random landmark",
  },
  "city": "Bengaluru",
  "state": "Karnataka",
  "country": "India"
});`;

function App() {
  useEffect(() => {
    makeLogs("firstName", "Varun");
    setTimeout(() => {
      makeLogs("lastName", "Bardwaj");
    }, 2000);
    setTimeout(() => {
      makeLogs("city", "Bengaluru");
    }, 3000);
    setTimeout(() => {
      makeLogs("state", "Karnataka");
    }, 4000);
    setTimeout(() => {
      makeLogs("country", "India");
    }, 5000);
    setTimeout(() => {
      makeLogs("json", {
        name: "Varun Bardwaj",
        address: {
          address_line_1:
            "random line 1 random line 1 random line 1 random line 1 random line 1 random line 1 random line 1 random line 1 random line 1 random line 1 random line 1 ",
          address_line_2: "random line 2",
          landmark: "random landmark",
        },
        city: "Bengaluru",
        state: "Karnataka",
        country: "India",
      });
    }, 6000);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexWrap: "wrap",
        overflowY: "hidden",
      }}
    >
      <div
        className="panel-left"
        style={{
          width: "calc(50% - 10px)",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#071428",
          borderRight: "2px solid #242D38",
          overflowY: "auto",
        }}
      >
        <img className="image" width="200" src={LOGO} alt="logo" />
        <span
          style={{
            color: "#eaeaff",
            fontSize: "25px",
            fontWeight: 700,
            marginTop: "30px",
          }}
        >
          React Logger
        </span>
      </div>
      <div
        className="panel-right"
        style={{
          width: "calc(50% + 5px)",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#071428",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            backgroundColor: "#0A1F3D",
            padding: "20px",
            width: "90%",
            marginTop: "30px",
          }}
        >
          <div style={{ color: "#9b96ff" }}>Push 1:</div>
          <div style={{ color: "#d9ceff", marginTop: "10px" }}>
            makeLogs("firstName", "Varun");
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#0A1F3D",
            padding: "20px",
            width: "90%",
            marginTop: "30px",
          }}
        >
          <div style={{ color: "#9b96ff" }}>Push 2:</div>
          <div style={{ color: "#d9ceff", marginTop: "10px" }}>
            makeLogs("lastName", "Bardwaj");
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#0A1F3D",
            padding: "20px",
            width: "90%",
            marginTop: "30px",
          }}
        >
          <div style={{ color: "#9b96ff" }}>Push 3:</div>
          <div style={{ color: "#d9ceff", marginTop: "10px" }}>
            makeLogs("city", "Bengaluru");
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#0A1F3D",
            padding: "20px",
            width: "90%",
            marginTop: "30px",
          }}
        >
          <div style={{ color: "#9b96ff" }}>Push 4:</div>
          <div style={{ color: "#d9ceff", marginTop: "10px" }}>
            makeLogs("state", "Karnataka");
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#0A1F3D",
            padding: "20px",
            width: "90%",
            marginTop: "30px",
          }}
        >
          <div style={{ color: "#9b96ff" }}>Push 5:</div>
          <div style={{ color: "#d9ceff", marginTop: "10px" }}>
            makeLogs("country", "India");
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#0A1F3D",
            padding: "20px",
            width: "90%",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          <div style={{ color: "#9b96ff" }}>Push 6:</div>
          <pre style={{ color: "#d9ceff", marginTop: "10px" }}>
            {makeLogsJSON}
          </pre>
        </div>
      </div>
      <LogProvider
        devURL={[
          "localhost:3000",
          "localhost:3001",
          "react-logger-js-demo-varunbardwaj.pages.dev",
        ]}
        x={1}
        y={1}
      />
    </div>
  );
}

export default App;
